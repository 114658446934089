<template>
  <wrap-header title="FAQ" />

  <div class="container pt-4">
    <div>
      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            What is wrapped PUTinCoin (wPUT)?
          </div>
          <div class="text-start">
            Wrapped PUTinCoin (wPUTinCoin/wPUT) is a synthetic PUTinCoin-backed
            token that can exist and be used on foreign EVM-based blockchains like
            Binance Chain. Binance Chain also is the only EVM chain where the
            wPUT token is available at the moment (more EVM chains to come).
            wPUTinCoins are created/minted when standard PUTinCoins are wrapped and
            destroyed/burned when they are unwrapped. For that you can use this
            wrapping/unwrapping bridge here at <a href="https://putincoin.to" target="_self">https://putincoin.to</a>.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            What is the purpose of wPUT?
          </div>
          <div class="text-start">
            PUTinCoin remains one of the oldest, most secure, and truly
            egalitarian blockchains in existence. PUTinCoin was prominent and
            easy to obtain on the first generation of cryptocurrency exchanges.
            Unfortunately, as the industry evolved, centralized exchanges became
            less and less hospitable to open-source, community driven projects
            like PUTinCoin. Now a new era of decentralized finance (DeFi) is
            restoring the freedom that is at the heart of blockchain technology.
            Through the process of wrapping PUTinCoin, the unrestricted exchange
            of PUT for other currencies is once again accessible to everyone.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Where can I find more details on how putincoin.to bridge works?
          </div>
          <div class="text-start">
            The architect of putincoin.to wrote this article describing how the bridge
            works and how to operate it. You&rsquo;ll also find a video tutorial below.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Can I use my original PUTinCoins on the Binance blockchain?
          </div>
          <div class="text-start">
            No, you cannot use real PUTinCoins on Binance Chain, since PUTinCoin lives
            on the PUTinCoin blockchain. You can, however, use wPUTinCoins (wPUT)
            on Binance Chain as they represent the smart contract version of PUTinCoin.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Can I use wPUT on blockchains other than Binance Chain?
          </div>
          <div class="text-start">
            Not at the moment. But putincoin.to can work with other EVM-based chains
            like Ethereum and Polygon. Once the support for these and other EVM chains
            is added, you will be able to select them from the dropdown list,
            but for now only Binance Chain is available (default selection).
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            What is the process for wrapping my PUTinCoins?
          </div>
          <div class="text-start">
            The wrapping process consists of five steps. In the section "Wrap PUTinCoin"
            you first must enter the amount of PUTinCoins that you want to wrap. Then 
            you need to send this amount in PUTinCoins to the freshly generated PUTinCoin
            deposit address. Your transaction needs to get 6 confirmations and bridge admin 
            will verify your deposit (this could take up to 24 hours but normaly it is 
            verfied within 5 minutes to 6 hours).<br />
            After verification is finished, you will be prompted to proceed to your
            MetaMask wallet to mint (fetch) your wPUT. Your wPUT tokens can then be
            found in and transacted from your MetaMask wallet.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            What is the process for unwrapping my wPUTinCoins?
          </div>
          <div class="text-start">
            The unwrapping process consists of just one step. In the section "Unwrap wPUTinCoin"
            you enter the amount of wPUTinCoins that you want to unwrap (revert back to original PUTinCoins) 
            as well as the PUTinCoin address where you want to get your unwrapped PUTinCoins
            sent to and click the "REDEEM WRAPPED PUTinCoin" button. You then get redirected
            to your MetaMask wallet and need to confirm the burning transaction.<br />
            If the burning was successful, you&rsquo;ll get back to the main page and your original
            PUTinCoins will be sent to the PUTinCoin address you stated within 24 hours. However,  
            based on exprerience, the average time to get your PUTinCoins delivered will be 
            between 30 minutes to 6 hours.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            HELP!! I started a wrap but got kicked out of browser!
          </div>
          <div class="text-start">
            If you get kicked out of the browser during a wrapping process (because
            your PC crashed or your mobile battery got empty), you don&rsquo;t need to
            get nervous. Just open <a href="https://putincoin.to" target="_self">https://putincoin.to</a> again, 
            connect your MetaMask, click on the "Wrap PUTinCoin" button and you will be
            redirected to the respective step, where you have been kicked out and can safely finish your wrap!
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">What is MetaMask?</div>
          <div class="text-start">
            MetaMask is a browser extension and mobile app that allows you to
            store, transact and swap ETH and other ERC-20 tokens. Currently
            MetaMask supports all Ethereum-based networks that follow the ERC-20
            token standard. putincoin.to is designed to work with
            MetaMask, so in order to use the wrapping and unwrapping features,
            you must first connect your MetaMask wallet to the putincoin.to website.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Can I use putincoin.to on my mobile phone?
          </div>
          <div class="text-start">
            Yes, MetaMask has an app compatible with iOS and Android. Open the
            MetaMask app on your phone and connect to putincoin.to using
            the mobile browser that is built into the app. This browser can be
            found in the MetaMask app menu.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Why can&rsquo;t I see my wPUT in my MetaMask wallet?
          </div>
          <div class="text-start">
            If you successfully minted your wPUT, but can&rsquo;t see the
            balance in your MetaMask wallet, it&rsquo;s likely that you
            didn&rsquo;t import the wPUT token into your wallet. In the MetaMask
            wallet, select "Import tokens", and then "Custom token". Fill in the
            token contract address, which is listed on putincoin.to main page
            at "OFFICIAL WRAPPED PUTINCOIN TOKEN". Also enter in the token symbol 
            (wPUT) and token decimal (8), if it is not filled in automatically. 
            Additionally, all this information can be filled in for you by selecting
            the button "Add wPUT TOKEN TO METAMASK" also on the putincoin.to main page. 
            Either way you do it, this action will import the wPUT token to your MetaMask 
            wallet, allowing you to see the wPUT balance you already got.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Can I stake my wPUT on my PUTinCoin staking wallet?
          </div>
          <div class="text-start">
            No, wPUTs cannot be used to stake coins on the PUTinCoin blockchain.
            wPUTs are bound by the rules of the blockchain they were created on and
            cannot directly communicate with the PUTinCoin blockchain. If you
            want to stake coins, you must first unwrap your wPUT using the
            putincoin.to website. By carrying out this process, your wPUT
            are burned, and "normal" PUTs are released from the bridge admin team 
            to your standard PUTinCoin address. 
            See "What is the process for unwrapping my wPUTinCoins?" above!
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Does putincoin.to charge a service fee?
          </div>
          <div class="text-start">
            The service itself charges a 1% fee in PUTinCoin, which will be
            deducted from your wPUT or PUT respectively. There are also
            smart contract transaction gas fees that must be paid in order to
            mint and burn wPUT. Also the transaction fees of the PUTinCoin
            blockhain have to be paid by you, like you always have, when you
            send PUTs on the PUTinCoin blockchain. One of the postulates of
            the design of putincoin.to is that the user always pays for all fees.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            Is there a minimum amount for making a wrap/unwrap?
          </div>
          <div class="text-start">
            Yes, there is: 10,000 PUT/wPUT!
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            I started a wrapping, but changed my mind. How do I cancel my session?
          </div>
          <div class="text-start">
            There is no way to cancel an active session if you already made a
            deposit. Complete the wrapping session. Once your wPUT are minted,
            you can then unwrap them again to receive your PUT back and vice versa.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            How can I be sure my deposited PUT are secure?
          </div>
          <div class="text-start">
            You can view the custodian balance on putincoin.to at any time.
            Most exploits/hacks of cross-chain bridges occur at the withdrawal step,
            usually by finding a way to fool an automatic process. Because of
            this, unwrapping and the release of PUT back to the user is a manual
            process, not automated. It is the safest option to ensure the
            deposited PUT remain secure.
          </div>
        </div>
      </div>

      <div class="row px-3 mt-2 mb-3">
        <div class="col-12">
          <div class="text-header fw-bold mb-2 question">
            HELP! I need Help!
          </div>
          <div class="text-start">
            Although the wrapping/unwrapping process is failsafe, it may occur that
            you make some mistake or you don't get your wPUT minting permission or
            your redeemed PUTinCoins within 24 hours. In this case you can write us
            a support request to <a href="mailto:help@putincoin.to?subject=PUTinCoin%20Bridge%20-%20Support%20Request&body=Dear%20Bridge%20team%2C%0D%0A%0D%0AI%20need%20support%20for%20following%20wrap%2Funwrap%3A%0D%0A%0D%0A%0D%0ATransaction%20ID%20(PUT%20or%2Fand%20BSC)%3A%20%0D%0A%0D%0AMy%20MetaMask%20address%3A%20%0D%0A%0D%0AIf%20it's%20about%20a%20wrap%20-%20Wrapping%20PUT%20address%20generated%20by%20bridge%3A%20%0D%0A%0D%0AIf%20it's%20about%20an%20unwrap%20-%20My%20target%20PUT%20address%3A%20%0D%0A%0D%0AWrapping%2FUnwrapping%20amount%3A%20%0D%0A%0D%0A%0D%0AMy%20issue%3A%20" target="_blank">help@putincoin.to</a>.
          </div>
        </div>
      </div>
    </div>

    <div class="row px-3 mt-3 mb-4">
      <div class="col-md-12">
        <div class="faqclose" style="margin-top: 30px !important;" @click="gotoHome">
          <span>GO BACK</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WrapHeader from "@/components/WrapHeader.vue";
export default {
  components: {
    WrapHeader,
  },

  methods: {
    gotoHome() {
      if (!!this.$store.state.account) {
        this.$router.push({
          name: "HomeAccount",
          params: {
            selectedaccount: [this.$store.state.account],
          },
        });
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  color: #e5c300;
}

.text-header {
  color: #e5c300;
}

.text-start {
  color: #fff;
  text-align: justify !important;
}

.faqclose {
  padding-bottom: 7px;
  padding-top: 7px;

  border: 1px solid #e5c300;
  // background-color: #fff;
  text-align: center;
  opacity: 1;
  font-size: 14px;
  background-color: #000000;
  color: #fff;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    background-color: #e5c300;
    color: #000;
  }
}
</style>
